import { IS_USER_LOGGED_IN, REMEMBER_ME, USER_LOGOUT } from './types'

export const setUserIsLoggedIn = (loggedin: boolean) => ({
  type: IS_USER_LOGGED_IN,
  loggedin,
})

export const setRememberMe = (remember: boolean) => ({
  type: REMEMBER_ME,
  remember,
})

export const userLoggedOut = () => ({
  type: USER_LOGOUT,
})
