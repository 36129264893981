import { styles } from '../styles'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import userImage from '../../assets/images/main/user.png'
import vehiclesImage from '../../assets/images/main/vehicles.png'
import paymentDetailsImage from '../../assets/images/main/payment_details.png'
import changePassord from '../../assets/images/main/change_password.png'
import faqs from '../../assets/images/main/faqs.png'
import transactions from '../../assets/images/main/transactions.png'
import { Link, useHistory } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

export default function Main() {
  const classes = useStyles()
  const history = useHistory()
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  )

  useEffect(() => {
    if (!isLoggedIn) {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper style={styles.paper}>
            <div>
              <p
                style={{ color: 'black', fontSize: '1em', textAlign: 'center' }}
              >
                To view and amend your Gatwick account details, select the icon
                to upgrade your information.
              </p>
            </div>
            <div style={{ display: 'grid', flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-around',
                  marginTop: 20,
                }}
              >
                {/** Personal details */}
                <div>
                  <Link to="/personaldetails">
                    <div className={classes.boxContainer}>
                      <img
                        src={userImage}
                        alt="userImage"
                        className={classes.imageWrapper}
                      />
                    </div>
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>Personal Details</label>
                  </div>
                </div>

                {/** Vehicles details */}
                <div>
                  <Link to="/editvehicles">
                    <div className={classes.boxContainer}>
                      <img
                        src={vehiclesImage}
                        alt="vehiclesImage"
                        className={classes.imageWrapper}
                      />
                    </div>
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>Vehicles</label>
                  </div>
                </div>

                {/** Payment details details */}
                <div>
                  <Link to="/editpaymentdetails">
                    <div className={classes.boxContainer}>
                      <img
                        src={paymentDetailsImage}
                        alt="paymentDetailsImage"
                        className={classes.imageWrapper}
                      />
                    </div>
                    {/* </Button> */}
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>Payment Details</label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'space-around',
                  marginTop: 20,
                }}
              >
                {/** Change password details */}
                <div>
                  <Link to="/changepassword">
                    <div className={classes.boxContainer}>
                      <img
                        src={changePassord}
                        alt="changePassord"
                        className={classes.imageWrapper}
                      />
                    </div>
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>Change Password</label>
                  </div>
                </div>

                {/** FAQs details */}
                <div>
                  <Link to="/faqs">
                    <div className={classes.boxContainer}>
                      <img
                        src={faqs}
                        alt="faqs"
                        className={classes.imageWrapper}
                      />
                    </div>
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>FAQs</label>
                  </div>
                </div>

                {/** Transactions details */}
                <div>
                  <Link to="/transactionhistory">
                    <div className={classes.boxContainer}>
                      <img
                        src={transactions}
                        alt="transactions"
                        className={classes.imageWrapper}
                      />
                    </div>
                  </Link>
                  <div className={classes.lblWrapper}>
                    <label className={classes.lblTitle}>Transactions</label>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      display: 'flex',
      justifyContent: 'center',
      width: '70%',
    },
    boxContainer: {
      width: 120,
      height: 130,
      display: 'flex',
      border: 'solid',
      borderColor: 'gray',
      borderWidth: '0.1em',
      justifyContent: 'center',
      alignItems: 'center',
      '@media (max-width: 375px)': {
        width: 80,
        height: 90,
      },
    },
    imageWrapper: {
      width: 75,
    },
    lblWrapper: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    lblTitle: {
      color: 'black',
      fontSize: '0.8em',
      '@media (max-width: 375px)': {
        fontSize: '0.5em',
      },
      fontWeight: 'bold',
      textAlign: 'center',
    },
  })
)
