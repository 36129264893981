import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function BottomButtons({
  onEditClicked = () => {},
  onBackClicked = {},
  editBtnTxt = '',
  showSaveBtn = true,
  loading = false,
}) {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={false}
      className={classes.gridContainer}
      style={{
        marginTop: 20,
        padding: 0,
        display: 'flex',
      }}
    >
      <Paper className={classes.paperOther}>
        <div
          style={{
            display: 'flex',
            // backgroundColor: 'orange',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Link
            to={onBackClicked}
            style={{ textDecoration: 'none', fontFamily: 'conduit' }}
          >
            <Button
              startIcon={<ArrowBackIosIcon />}
              className={classes.buttonBack}
              variant="outlined"
            >
              Back
            </Button>
          </Link>

          {showSaveBtn &&
            (loading ? (
              <CircularProgress />
            ) : (
              <Button className={classes.buttonSave} onClick={onEditClicked}>
                {editBtnTxt}
              </Button>
            ))}
        </div>
      </Paper>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
    },
    paperOther: {
      backgroundColor: 'transparent',
      '@media (max-width: 675px)': {
        padding: 1,
      },
      padding: 5,
      boxShadow: 'none',
      width: '100%',
    },
    buttonBack: {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'black',
      borderWidth: 1,
      borderRadius: 2,
      width: 130,
      justifyContent: 'space-between',
      height: 25,
      fontFamily: 'conduit',
      fontSize: '0.7em',
      textTransform: 'none',
    },
    buttonSave: {
      color: 'white',
      backgroundColor: '#004489',
      width: 130,
      fontSize: '0.7em',
      height: 25,
      textTransform: 'none',
      fontFamily: 'conduit',
      '&:hover': {
        backgroundColor: '#004489',
        color: '#FFF',
      },
    },
  })
)
