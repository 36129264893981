import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export default function BodyHeader({ titleStep = '', title = '' }) {
  const classes = useStyles()
  return (
    <Grid item xs={false} className={classes.gridContainer}>
      <Paper className={classes.paper}>
        <label style={{ fontSize: 13, fontWeight: 'bolder', color: '#66686a' }}>
          {titleStep}
        </label>
        <label className={classes.containerTitle}>{title}</label>
        <div className={classes.topDivider} />
      </Paper>
    </Grid>
  )
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
      },
      width: '70%',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    containerTitle: {
      fontWeight: 'bolder',
      '@media (max-width: 675px)': {
        fontSize: 20,
      },
      fontSize: 32,
      color: 'black',
    },
    topDivider: {
      backgroundColor: '#F3F3F3',
      height: 1,
      marginTop: 15,
      marginBottom: 15,
    },
  })
)
