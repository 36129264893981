import React from 'react'
import RouteConfig from './route'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <RouteConfig />
      </PersistGate>
    </Provider>
  )
}

export default App
