import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "../screen";
import Register from "../screen/register";
import PaymentDetails from "../screen/paymentDetails";
import FinishedRegister from "../screen/paymentDetails/FinishedRegister";
import { Header, Footer } from "../components";
import Main from "../screen/main";
import PersonalDetails from "../screen/main/PersonalDetails";
import EditPaymentDetails from "../screen/main/EditPaymentDetails";
import EditVehicles from "../screen/main/EditVehicles";
import TransactionHistory from "../screen/main/TransactionHistory";
import PostPay from "../screen/postpay";
import PrePay from "../screen/prepay";
import ForgotPassword from "../screen/forgotpassword";
import ChangePassword from "../screen/main/ChangePassword";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Faqs from "../screen/main/Faqs";

export default function RouteConfig() {
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  );

  return (
    <Router>
      <Header />
      {!isLoggedIn ? (
        <Switch>
          <Route exact path="/" component={Login}>
            <Login />
          </Route>
          <Route path="/register" component={Register}>
            <Register />
          </Route>
          <Route path="/forgotten" component={ForgotPassword}>
            <ForgotPassword />
          </Route>
          <Route path="/postpay" component={PostPay}>
            <PostPay />
          </Route>
          <Route path="/paymentDetails" component={PaymentDetails}>
            <PaymentDetails />
          </Route>
          <Route path="/finishedRegister" component={FinishedRegister}>
            <FinishedRegister />
          </Route>
               <Route path="/prepay" component={PrePay}>
            <PrePay />
          </Route>
          <Route path="/editpaymentdetails" component={EditPaymentDetails}>
            <EditPaymentDetails />
          </Route>
          <Redirect to="/" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/main" component={Main}>
            <Main />
          </Route>
          <Route path="/personaldetails" component={PaymentDetails}>
            <PersonalDetails />
          </Route>
          <Route path="/editpaymentdetails" component={EditPaymentDetails}>
            <EditPaymentDetails />
          </Route>
          <Route path="/editvehicles" component={EditVehicles}>
            <EditVehicles />
          </Route>
          <Route path="/changepassword" component={ChangePassword}>
            <ChangePassword />
          </Route>
          <Route path="/faqs" component={Faqs}>
            <Faqs />
          </Route>
          <Route path="/prepay" component={PrePay}>
            <PrePay />
          </Route>
          <Route path="/transactionhistory" component={TransactionHistory}>
            <TransactionHistory />
          </Route>
          <Redirect to="/main" />
        </Switch>
      )}
      <Footer />
    </Router>
  );
}
