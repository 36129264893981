import { styles } from "../styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CheckMarkImage from "../../assets/images/payment/check_mark.png";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default function FinishedRegister() {
  const classes = useStyles();

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={"center"}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper style={styles.paper}>
            <div className={classes.container}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={CheckMarkImage}
                  alt="PaypalImage"
                  className={classes.wrapper}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "1.5em",
                  }}
                >
                  Thank You.
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ color: "black", fontSize: "0.7em" }}>
                  You have been registered
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  <Button className={classes.buttonSave}>Back to Login</Button>
                </Link>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "grid",
      borderColor: "gray",
      border: "solid",
      color: "blue",
      borderWidth: 0.5,
      height: 300,
      width: 400,
      "@media (max-width: 675px)": {
        height: 300,
        width: 350,
      },
      justifyContent: "space-around",
      alignItems: "center",
    },
    gridContainer: {
      "@media (max-width: 675px)": {
        width: "100%",
        padding: 5,
      },
      display: "flex",
      justifyContent: "center",
      width: "70%",
    },
    wrapper: {
      width: 75,
    },
    buttonSave: {
      color: "white",
      backgroundColor: "#004489",
      width: 130,
      fontSize: 12,
      height: 35,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#004489",
        color: "#FFF",
      },
    },
  })
);
