import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import gatwickLogo from '../assets/images/gatwick-airport-logo.svg'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../redux/store'
import { setUserIsLoggedIn, userLoggedOut } from '../redux/actions/auth'
import { logoutRequest } from '../services/api'
import configData from '../screen/config/index.json'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useState } from 'react'

export default function Header() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  )

  async function logoutClicked() {
    setIsLoading(true)
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await logoutRequest(data)

      if (response.data.status === 'success') {
        setIsLoading(false)
        dispatch(setUserIsLoggedIn(false))
        dispatch(userLoggedOut())
        history.replace('/')
      } else {
        setIsLoading(false)
        alert('Unable to logout')
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alert(error.response.data.message)
      }
    }
  }

  return (
    <AppBar position="static" className={classes.AppBarContainer}>
      <div className={classes.container}>
        <Toolbar style={{ padding: 0 }}>
          <Button
            onClick={() =>
              isLoggedIn ? history.replace('/main') : history.replace('/')
            }
            className={classes.AppBarTitle}
          >
            <img
              src={gatwickLogo}
              alt="gatwick"
              style={{ height: 65, width: 240 }}
            />
          </Button>

          <Button
            className={classes.loginButton}
            onClick={() => (isLoggedIn ? logoutClicked() : logoutClicked())}
          >
            {isLoggedIn ? (
              isLoading ? (
                <CircularProgress />
              ) : (
                'Log out'
              )
            ) : (
              'Login'
            )}
          </Button>
        </Toolbar>
      </div>
    </AppBar>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '@media (max-width: 675px)': {
      width: '90%',
    },
    width: '70%',
  },
  AppBarTitle: {
    fontWeight: 'bold',
    color: 'black',
    fontSize: 22,
    letterSpacing: 1,
    textTransform: 'none',
    fontFamily: 'conduit',
  },
  AppBarContainer: {
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    '@media (max-width: 675px)': {
      fontSize: 14,
    },
  },
  loginButton: {
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'conduit',
    fontSize: 16,
    textTransform: 'none',
    flex: 1,
    justifyContent: 'flex-end',
  },
  lblSize: {
    '@media (max-width: 675px)': {
      fontSize: 12,
    },
    fontSize: 14,
  },
}))
