import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Theme,
  withStyles,
  createStyles,
} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
// import Radio from '@material-ui/core/Radio'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { Link, useHistory } from 'react-router-dom'
import configData from './config/index.json'
import { getUserAccountRequest, loginRequest } from '../services/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { setUserIsLoggedIn, userLoggedOut } from '../redux/actions/auth'
import { setUserInfo } from '../redux/actions/user'
import ReactGA from 'react-ga'

export default function Login() {
  const classes = useStyles()
  const history = useHistory()
  // const rememberMe = useSelector(
  //   (state: RootState) => state.authReducer.rememberMe
  // )
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.isLoggedIn
  )
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  // const [value, setValue] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassord] = useState('')

  useEffect(() => {
    if (window.location.origin === 'http://dropoff.gatwickairport.com') {
      ReactGA.initialize('UA-13042300-1')
    }
    if (isLoggedIn) {
      dispatch(setUserIsLoggedIn(false))
      dispatch(userLoggedOut())
    } else {
      history.replace('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // function handleClick(event: any) {
  //   if (event.target.value === value) {
  //     setValue('')
  //   } else {
  //     setValue(event.target.value)
  //   }
  // }

  function handleEmaiLChanged(email: any | null) {
    setEmail(email.target.value)
  }

  function handlePasswordChanged(password: any | null) {
    setPassord(password.target.value)
  }

  async function login() {
    if (email === '') {
      alert('Email cannot be empty!')
      return
    } else if (password === '') {
      alert('Password cannot be empty!')
      return
    }

    setIsLoading(true)
    try {
      let data = {
        email: email,
        password: password,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await loginRequest(data)

      if (response.data.status === 'success') {
        localStorage.setItem('token', response.data.data.token)
        dispatch(setUserIsLoggedIn(true))
        // if (value !== '') {
        //   // dispatch(setRememberMe(true))
        // } else {
        //   // dispatch(setRememberMe(false))
        // }
        getUserInfo()
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message)
      }
      console.log('error', error)
      setIsLoading(false)
    }
  }

  // function handlePrepayClicked() {
  //   alert('Coming soon')
  //   return
  // }

  async function getUserInfo() {
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await getUserAccountRequest(data)

      if (response.data.status === 'success') {
        dispatch(setUserInfo(response.data.message))
        history.push('/main')
        setIsLoading(false)
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
    }
  }

  function keyPressed(e: any) {
    if (e.key === 'Enter') {
      login()
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <label className={classes.containerTitle}>
              Already used drop off zone
            </label>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <label
                style={{ fontSize: 12, color: 'black', fontWeight: 'normal' }}
              >
                Please click here to pay.
              </label>
            </div>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        {/** Other button option layout */}
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'block',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                marginTop: 10,
                marginBottom: 45,
              }}
            >
              <div
                style={{
                  justifyContent: 'space-around',
                  display: 'flex',
                  marginTop: 10,
                }}
              >
                <Link
                  to={{
                    pathname:
                      'https://postpay.dropoff.gatwickairport.com/?customKey=33095c6292f04fc29ec34229fdd6ce20',
                  }}
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  <Button className={classes.loginButton}>PostPay</Button>
                </Link>
              </div>
            </div>
            <div className={classes.otherDivider} />
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <label className={classes.containerTitle}>
              Sign in or Register for AutoPay
            </label>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <label
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: 'black',
                  textTransform: 'none',
                }}
              >
                Please note:&nbsp;
              </label>
              <label
                style={{ fontSize: 12, color: 'black', fontWeight: 'normal' }}
              >
                This is not your myGatwick account
              </label>
            </div>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        {/** Email layout */}
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <label style={{ color: 'black', fontSize: 14 }}>Email</label>
              <CssTextField
                id="outlined-basic"
                label=""
                variant="outlined"
                required
                value={email}
                onChange={handleEmaiLChanged}
                style={{ width: '80%', height: 30 }}
              />
            </div>
            <div
              style={{
                // backgroundColor: 'green',
                textAlign: 'end',
              }}
            >
              <Link
                to={'/register'}
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Register for account?
              </Link>
            </div>
          </Paper>
        </Grid>

        {/** Password layout */}
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <label style={{ color: 'black', fontSize: 14 }}>Password</label>
              <CssTextField
                id="outlined-basic"
                label=""
                variant="outlined"
                required
                type="password"
                value={password}
                onKeyDown={keyPressed}
                onChange={handlePasswordChanged}
                style={{ width: '80%', height: 30, color: 'black' }}
              />
            </div>
            <div
              style={{
                // backgroundColor: 'green',
                textAlign: 'end',
              }}
            >
              <Link
                to="/forgotten"
                style={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >
                Forgotten?
              </Link>
            </div>
          </Paper>
        </Grid>

        {/** Check box layout */}
        {/* <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="rememberMe"
                  name="rememberMe"
                  value={value}
                >
                  <FormControlLabel
                    value="rememberMe"
                    control={
                      <Radio style={{ color: 'black' }} onClick={handleClick} />
                    }
                    label="Remember me"
                    style={{ color: 'black' }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Paper>
        </Grid> */}

        {/** Login button layout */}
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginBottom: 60,
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button className={classes.loginButton} onClick={() => login()}>
                  Login
                </Button>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 130,
    },
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
      },
      width: '70%',
    },
    paper: {
      '@media (max-width: 675px)': {
        padding: theme.spacing(1),
      },
      padding: theme.spacing(0),
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    paperOther: {
      backgroundColor: 'transparent',
      '@media (max-width: 675px)': {
        padding: 1,
      },
      padding: 5,
      boxShadow: 'none',
    },
    containerTitle: {
      fontWeight: 'bolder',
      '@media (max-width: 675px)': {
        fontSize: 20,
      },
      fontSize: 32,
      color: 'black',
    },
    topDivider: {
      backgroundColor: '#F3F3F3',
      height: 1,
      marginTop: 15,
    },
    otherDivider: {
      backgroundColor: '#F3F3F3',
      height: 1,
    },
    loginButton: {
      color: 'white',
      fontFamily: 'conduit',
      fontSize: 18,
      textTransform: 'none',
      backgroundColor: '#004489',
      '@media (max-width: 675px)': {
        width: 100,
      },
      '&:hover': {
        backgroundColor: '#004489',
        color: '#FFF',
      },
      width: 150,
      height: 40,
    },
  })
)

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#F3F3F3',
      borderWidth: 2,
      borderRadius: 2,
      color: 'black',
      height: 30,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#004489',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      padding: 5,
    },
    '&:hover .MuiOutlinedInput-input': {
      color: 'black',
    },
  },
})(TextField)
