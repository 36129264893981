import React, { useState } from 'react'
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { CustomTextField } from '../../components'
import TextField from '@material-ui/core/TextField'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { Link, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  createAccountRequet,
  getUserAccountRequest,
  loginRequest,
} from '../../services/api'
import configData from '../config/index.json'
import CircularProgress from '@material-ui/core/CircularProgress'
import isEmail from 'validator/lib/isEmail'
// import isMobilePhone from 'validator/lib/isMobilePhone'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../redux/actions/user'

export default function Register() {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const [value, setValue] = React.useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [emailAdd, setEmailAdd] = useState('')
  const [password, setPassword] = useState('')
  const [vehicleReg, setVheicleReg] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [showHideTxt, setShowHideTxt] = useState('Show')
  const [isLoading, setIsLoading] = useState(false)

  function handleClick(event: any) {
    if (event.target.value === value) {
      setValue('')
    } else {
      setValue(event.target.value)
    }
  }

  function handleFirstNameChanged(name: any | null) {
    setFirstName(name.target.value)
  }

  function handleLastNameChanged(name: any | null) {
    setLastName(name.target.value)
  }

  function handlePhoneNoChanged(phone: any | null) {
    if (phone.target.value === '') {
      setPhoneNo(phone.target.value)
      setIsPhoneValid(false)
      return
    }

    if (phone.target.value.length < 8 || phone.target.value.length > 15) {
      setIsPhoneValid(true)
      setPhoneNo(phone.target.value)
      return
    }

    setIsPhoneValid(false)
    setPhoneNo(phone.target.value)
  }

  function handleEmaiLChanged(email: any | null) {
    if (email.target.value === '') {
      setEmailAdd(email.target.value)
      setInvalidEmail(false)
      return
    }
    if (!isEmail(email.target.value)) {
      setInvalidEmail(true)
      setEmailAdd(email.target.value)
      return
    }

    setInvalidEmail(false)
    setEmailAdd(email.target.value)
  }

  function handlePasswordChanged(password: any | null) {
    setPassword(password.target.value)
  }

  function handleVehicleRegChanged(reg: any | null) {
    const onlyAlphanumericRegex = /[!@#$%^&*(),.?":{}|<>]/g

    if (onlyAlphanumericRegex.test(reg.target.value)) {
      return
    }

    setVheicleReg(reg.target.value)
  }

  function handleShowPassClicked() {
    if (showHideTxt === 'Show') {
      setShowHideTxt('Hide')
    } else if (showHideTxt === 'Hide') {
      setShowHideTxt('Show')
    }
  }

  async function handleRegisterClicked() {
    if (firstName === '') {
      alert('Enter Name')
      return
    } else if (lastName === '') {
      alert('Enter Last name')
      return
    }

    setIsLoading(true)
    try {
      let data = {
        name: firstName,
        lastname: lastName,
        email: emailAdd,
        phone: phoneNo,
        password: password,
        password_confirmation: password,
        reg: vehicleReg,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      let LoginData = {
        email: emailAdd,
        password: password,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await createAccountRequet(data)

      console.log('response', response)

      if (response.data.status === 'success') {
        localStorage.setItem('token', response.data.data.token)
        getUserInfo()
        await loginRequest(LoginData)
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }

      console.log('response', response)
    } catch (error) {
      if (error.response) {
        alert(error.response.data.message)
      }
      console.log('error', error.response)
      setIsLoading(false)
    }
  }

  async function getUserInfo() {
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await getUserAccountRequest(data)

      if (response.data.status === 'success') {
        dispatch(setUserInfo(response.data.data))
        history.push('/paymentDetails')
        setIsLoading(false)
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <label
              style={{ fontSize: 13, fontWeight: 'bolder', color: '#66686a' }}
            >
              Step 1 of 2
            </label>
            <Typography variant="h6" className={classes.containerTitle}>
              Register
            </Typography>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        {/** First Name Field */}
        <CustomTextField
          fieldTitle="First name"
          justifyContent="flex-start"
          txtValue={firstName}
          helperText=" "
          style={{ height: 40 }}
          onChange={handleFirstNameChanged}
        />
        {/** Last Name Field */}
        <CustomTextField
          fieldTitle="Last name"
          justifyContent="flex-start"
          txtValue={lastName}
          helperText=" "
          style={{ height: 40 }}
          onChange={handleLastNameChanged}
        />
        {/** Phone Field */}
        <CustomTextField
          error={isPhoneValid ? true : false}
          fieldTitle="Phone"
          justifyContent="flex-start"
          txtValue={phoneNo}
          helperText=" "
          type="text"
          style={{ height: 40 }}
          onChange={handlePhoneNoChanged}
        />
        {/** Email Field */}
        <CustomTextField
          error={invalidEmail ? true : false}
          fieldTitle="Email"
          justifyContent="flex-start"
          txtValue={emailAdd}
          helperText={invalidEmail ? 'Invalid Email ' : ' '}
          style={{ height: 40 }}
          onChange={handleEmaiLChanged}
        />
        {/** Password Field */}
        <CustomTextField
          isPasswordField={true}
          fieldTitle="Password"
          justifyContent="flex-start"
          txtValue={password}
          showHide={showHideTxt}
          style={{ height: 40 }}
          helperText=" "
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          onClick={handleShowPassClicked}
          type={showHideTxt === 'Hide' ? 'text' : 'password'}
          onChange={handlePasswordChanged}
        />

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // backgroundColor: 'blue',
              }}
            >
              <div
                className={classes.lblVehicleContainer}
                style={
                  {
                    // backgroundColor: 'green',
                  }
                }
              >
                {/** Label style enter vehicle */}
                <div
                  style={{
                    flex: 1,
                    // backgroundColor: 'red',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <label className={classes.lblEnterVehicle}>
                    Enter vehicle registration
                  </label>
                </div>

                {/** Label style enter vehicle */}
                <div className={classes.inputVehicleContainer}>
                  <div
                    style={{
                      backgroundColor: 'black',
                      borderRadius: 99,
                      width: 10,
                      height: 10,
                    }}
                  />

                  <CssTextField
                    id="outlined-basic"
                    label=""
                    placeholder="e.g ABC 123456"
                    variant="outlined"
                    required
                    value={vehicleReg}
                    onChange={handleVehicleRegChanged}
                    style={{
                      width: 200,
                      height: 30,
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  />

                  <div
                    style={{
                      backgroundColor: 'black',
                      borderRadius: 99,
                      width: 10,
                      height: 10,
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}></div>
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        {/** Check box layout */}
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                // justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="acceptTerms"
                  name="acceptTerms"
                  value={value}
                >
                  <FormControlLabel
                    value="acceptTerms"
                    control={
                      <Radio style={{ color: 'black' }} onClick={handleClick} />
                    }
                    label={
                      <label className={classes.lblSize}>
                        I've read and agree to the
                      </label>
                    }
                    style={{ color: 'black' }}
                  />
                </RadioGroup>
              </FormControl>

              <Button
                className={classes.button}
                onClick={() => {
                  window.open(
                    'https://www.gatwickairport.com/terms-conditions/drop-off-charge-terms-and-conditions/',
                    '_blank'
                  )
                }}
              >
                Terms and Conditions
              </Button>
            </div>
          </Paper>
        </Grid>

        {/** Bottom buttons layout */}
        <Grid
          item
          xs={false}
          className={classes.gridContainer}
          style={{
            marginTop: 20,
            padding: 5,
            display: 'flex',
            marginBottom: 100,
          }}
        >
          <Paper className={classes.paperOther}>
            <div
              style={{
                display: 'flex',
                // backgroundColor: 'orange',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Link to={'/'} style={{ textDecoration: 'none' }}>
                <Button
                  startIcon={<ArrowBackIosIcon />}
                  className={classes.buttonBack}
                  variant="outlined"
                >
                  Back
                </Button>
              </Link>

              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  className={classes.buttonSave}
                  disabled={value !== '' ? false : true}
                  style={{ backgroundColor: '#004489' }}
                  onClick={() => handleRegisterClicked()}
                >
                  Save and Continue
                </Button>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '@media (max-width: 675px)': {
        marginTop: 100,
        marginBottom: 70,
      },
      marginTop: 130,
    },
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    containerTitle: {
      fontWeight: 'bolder',
      '@media (max-width: 675px)': {
        fontSize: 20,
      },
      fontSize: 30,
      color: 'black',
    },
    topDivider: {
      backgroundColor: '#F3F3F3',
      height: 1,
      marginTop: 15,
      marginBottom: 15,
    },
    paperOther: {
      backgroundColor: 'transparent',
      '@media (max-width: 675px)': {
        padding: 1,
      },
      padding: 5,
      boxShadow: 'none',
      width: '100%',
    },
    buttonBack: {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'black',
      borderWidth: 1,
      fontFamily: 'conduit',
      borderRadius: 2,
      width: 130,
      fontSize: 14,
      justifyContent: 'space-between',
      height: 25,
      textTransform: 'none',
    },
    buttonSave: {
      color: 'white',
      width: 130,
      fontSize: 14,
      height: 25,
      textTransform: 'none',
      fontFamily: 'conduit',
      '&:hover': {
        backgroundColor: '#004489',
        color: '#FFF',
      },
    },
    lblVehicleContainer: {
      flex: 1,
      '@media (max-width: 675px)': {
        display: 'block',
      },
      display: 'flex',
      justifyContent: 'center',
    },
    lblEnterVehicle: {
      flex: 1,
      color: 'black',
      '@media (max-width: 675px)': {
        textAlign: 'center',
        marginBottom: 10,
      },
      textAlign: 'left',
    },
    inputVehicleContainer: {
      backgroundColor: '#fbda33',
      '@media (max-width: 675px)': {
        width: '100%',
      },
      width: 275,
      height: 35,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    lblSize: {
      '@media (max-width 675px)': {
        fontSize: 12,
      },
      fontSize: 14,
      fontFamily: 'conduit',
    },
    button: {
      color: 'black',
      fontWeight: 'bold',
      textTransform: 'none',
      textDecoration: 'underline',
      fontSize: 13,
      fontFamily: 'conduit',
      marginLeft: -15,
    },
  })
)

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderWidth: 2,
      borderRadius: 2,
      color: 'black',
      height: 30,
    },

    // '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    //   borderColor: 'red',
    // },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      padding: 5,
      textAlign: 'center',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: 'black',
      textAlign: 'center',
    },
    // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    //   color: 'purple',
    // },
    // '& .MuiInputLabel-outlined': {
    //   color: 'green',
    // },
    // '&:hover .MuiInputLabel-outlined': {
    //   color: 'red',
    // },
    // '& .MuiInputLabel-outlined.Mui-focused': {
    //   color: 'purple',
    // },
  },
})(TextField)
