import axios from 'axios'

import { apiDomain } from './domains'

async function customFetch({ url = '', ...options }) {
  return axios({
    withCredentials: false,
    url: url,
    ...options,
  })
}

export default class request {
  static headers = async () => {
    let token = localStorage.getItem('token')

    return {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers':
        'x-requested-with, Content-Type, origin, authorization, accept, client-security-token, API-Key',
    }
  }

  static post = async (path: any, data: any) => {
    const headers = await request.headers()
    return customFetch({
      method: 'POST',
      url: apiDomain + path,
      headers,
      mode: 'cors',
      data,
    })
  }

  static get = async (path: any) => {
    const headers = await request.headers()
    return customFetch({
      method: 'get',
      credentials: 'include',
      url: apiDomain + path,
      headers,
    })
  }
}
