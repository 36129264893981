import Grid from '@material-ui/core/Grid'
import { styles } from '../styles'
import { BodyHeader, BottomButtons } from '../../components'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function Faqs() {
  const classes = useStyles()

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={1}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Gatwick AutoPay FAQs" titleStep="" />

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>What is AutoPay?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  AutoPay is a feature which allows you to pay for drop off
                  automatically when you drive in and out of the drop off area.
                  There is no need to use the pay machine as the cost of your
                  drop off will be charged automatically to your Gatwick AutoPay
                  account when you drive out.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>How do I disable AutoPay?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  Autopay cannot be disabled, however you can remove your each
                  of vehicles from your account via the Vehicles page.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>How does Autopay work?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  Autopay works by using ANPR cameras at entry and exit to
                  record vehicle registration numbers. This means our system
                  knows how long you stayed with us and can calculate the
                  according price for your drop off. You're then charged
                  automatically with payment taken on or after you drive out the
                  drop off area.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>How do I pay for drop off using AutoPay?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  To pay using AutoPay you must have completed registration
                  before arriving at the drop off and have added the vehicle you
                  are using to your account. You then drive in and out of drop
                  off area as needed, and your payment will automatically be
                  charged the appropriate rate when you exit the drop off area.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>How I get my receipt for my AutoPay transaction?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  You receipt will be sent to you via the email address you
                  provided upon registration.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>
                  How do I know I've definitely been charged when I use Autopay?
                </label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  You can check your drop off history in the Transaction History
                  menu option, every AutoPay session will appear here after
                  you've driven out the drop off zone. You'll also receive a
                  payment confirmation email.
                </label>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ backgroundColor: '#004489' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <label>What payment methods are available for AutoPay?</label>
              </AccordionSummary>
              <AccordionDetails>
                <label>
                  You can pay by credit/debit cards (Visa, MasterCard and AMEX)
                  as well as Google Pay and Apple Pay.
                </label>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <BottomButtons showSaveBtn={false} onBackClicked="/main" />
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)
