import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { BottomButtons, CustomTextField } from '../../components'
import isEmail from 'validator/lib/isEmail'
import configData from '../config/index.json'
import { forgottenPassRequest } from '../../services/api'
import { useHistory } from 'react-router-dom'

export default function ForgotPassword() {
  const classes = useStyles()
  const history = useHistory()
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [emailAdd, setEmailAdd] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function handleEmaiLChanged(email: any | null) {
    if (email.target.value === '') {
      setEmailAdd(email.target.value)
      setInvalidEmail(false)
      return
    }
    if (!isEmail(email.target.value)) {
      setInvalidEmail(true)
      setEmailAdd(email.target.value)
      return
    }

    setInvalidEmail(false)
    setEmailAdd(email.target.value)
  }

  async function onForgottenClicked() {
    if (emailAdd === '') {
      alert('Email field cannot be empty')
      return
    }

    setIsLoading(true)

    try {
      let data = {
        email: emailAdd,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await forgottenPassRequest(data)

      if (response.data.status === 'success') {
        alert('An email with instructions has now been sent.')
        history.push('/')
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
    }
  }

  function keyPressed(e: any) {
    if (e.key === 'Enter') {
      onForgottenClicked()
    }
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <label className={classes.containerTitle}>Forgotten Password</label>
            <div className={classes.topDivider} />
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <div style={{ marginBottom: 20 }}>
              <label style={{ color: 'black', fontSize: 14 }}>
                Enter your email address to receive a link to reset your
                password
              </label>
            </div>
            <CustomTextField
              error={invalidEmail ? true : false}
              fieldTitle="Email"
              justifyContent="flex-start"
              txtValue={emailAdd}
              helperText={invalidEmail ? 'Invalid Email ' : ' '}
              style={{ height: 40 }}
              id="standard-error"
              onKeyDown={keyPressed}
              color="primary"
              onChange={handleEmaiLChanged}
            />
          </Paper>
        </Grid>

        <BottomButtons
          editBtnTxt="Send"
          loading={isLoading}
          onEditClicked={() => onForgottenClicked()}
          onBackClicked="/"
        />
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '@media (max-width: 675px)': {
        marginTop: 100,
      },
      marginTop: 130,
    },
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    containerTitle: {
      fontWeight: 'bolder',
      '@media (max-width: 675px)': {
        fontSize: 20,
      },
      fontSize: 32,
      color: 'black',
    },
    topDivider: {
      backgroundColor: '#F3F3F3',
      height: 1,
      marginTop: 15,
      marginBottom: 15,
    },
  })
)
