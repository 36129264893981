import { createStore, combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import authReducer from './reducers/authReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import userReducer from './reducers/userReducer'

const appReducer = combineReducers({
  authReducer: authReducer,
  userReducer: userReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    localStorage.removeItem('token')
    state = undefined
  }

  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, undefined)

export const persistor = persistStore(store)

export type RootState = StateType<typeof appReducer>
