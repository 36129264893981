import { styles } from '../styles'
import Grid from '@material-ui/core/Grid'
import { BodyHeader, BottomButtons, VehicleComponent } from '../../components'
import { useEffect, useState } from 'react'
import { addVehicleRequest, getVehiclesRuquest } from '../../services/api'
import configData from '../config/index.json'
import CircularProgress from '@material-ui/core/CircularProgress'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'

export default function EditVehicles() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [newReg, setNewReg] = useState('')

  useEffect(() => {
    getVehicles()
  }, [])

  async function getVehicles() {
    setIsLoading(true)
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await getVehiclesRuquest(data)

      if (response.data.status === 'success') {
        setIsLoading(false)
        setVehicles(response.data.data)
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
    }
  }

  async function onBtnAddClicked() {
    if (newReg === '') {
      alert('Registration field cannot be empty')
      return
    }

    setIsCreateLoading(true)
    try {
      let data = {
        reg: newReg,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await addVehicleRequest(data)

      if (response.data.status === 'success') {
        setNewReg('')
        setShowAddDialog(false)
        setIsCreateLoading(false)
        getVehicles()
      } else {
        alert(response.data.data.message)
        setIsCreateLoading(false)
      }
    } catch (error) {
      setIsCreateLoading(false)
      if (error.response) {
        alert(error.response.data.message)
      }
    }
  }

  function handleNewReg(reg: any | '') {
    const onlyAlphanumericRegex = /[!@#$%^&*(),.?":{}|<>]/g

    if (onlyAlphanumericRegex.test(reg.target.value)) {
      return
    }

    setNewReg(reg.target.value)
  }

  function keyPressed(e: any) {
    if (e.key === 'Enter') {
      onBtnAddClicked()
    }
  }

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={1}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Vehicles" titleStep="" />

        {isLoading ? (
          <Grid item xs={false} className={classes.gridContainer}>
            <Paper className={classes.paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            </Paper>
          </Grid>
        ) : (
          Object.entries(vehicles).map(([key, item]) => (
            <VehicleComponent
              vehcileTitle="Registration"
              item={item}
              getVehicles={() => getVehicles()}
            />
          ))
        )}

        <BottomButtons
          editBtnTxt="Add Another Vehicle"
          onBackClicked="/main"
          onEditClicked={() => setShowAddDialog(true)}
        />
      </Grid>

      {showAddDialog && (
        <Dialog
          open={showAddDialog}
          onClose={() => setShowAddDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ backgroundColor: '#004489' }}
          >
            <label style={{ fontFamily: 'conduit', fontSize: 20 }}>
              Add new vehicle
            </label>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: '#004489' }}>
            <DialogContentText>
              <label style={{ fontFamily: 'conduit', fontSize: 16 }}>
                Enter your registration to add a new vehicle
              </label>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="standard-basic"
              value={newReg}
              onChange={handleNewReg}
              InputLabelProps={{
                style: { color: '#fff', fontFamily: 'conduit' },
              }}
              label="Registration"
              type="text"
              variant="filled"
              onKeyDown={keyPressed}
              fullWidth
            />
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#004489' }}>
            <Button
              onClick={() => {
                setShowAddDialog(false)
                setNewReg('')
              }}
              color="primary"
              style={{ color: 'red', fontFamily: 'conduit' }}
            >
              Cancel
            </Button>
            {isCreateLoading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              <Button
                onClick={() => onBtnAddClicked()}
                style={{ color: 'white', fontFamily: 'conduit' }}
              >
                CREATE
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notchedOutline: {
      borderWidth: '1px',
      borderColor: 'yellow !important',
      color: 'red',
    },
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)
