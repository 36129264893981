export const styles = {
  root: {
    flexGrow: 1,
    '@media (max-width: 675px)': {
      marginTop: 50,
    },
    marginTop: 130,
    marginBottom: 100,
  },
  gridContainer: {
    '@media (max-width: 675px)': {
      width: '100%',
      padding: 5,
    },
    width: '70%',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}
