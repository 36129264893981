import React, { useEffect, useState } from 'react'
import { styles } from '../styles'
import Grid from '@material-ui/core/Grid'
import { BodyHeader, BottomButtons } from '../../components'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import configData from '../config/index.json'
import { resendReceipt, transactionsRequest } from '../../services/api'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

interface Column {
  id:
    | 'vehicleReg'
    | 'terminal'
    | 'paymentDate'
    | 'transactionType'
    | 'payment'
    | 'receipt'
    | 'activityId'
  label: string
  minWidth?: number
  align?: 'left'
  //   format?: (value: number) => string
}

const columns: Column[] = [
  {
    id: 'vehicleReg',
    label: 'Vehicle Reg',
    //    minWidth: 170
  },
  {
    id: 'terminal',
    label: 'Terminal',
    //    minWidth: 100
  },
  {
    id: 'paymentDate',
    label: 'Payment Date',
    // minWidth: 170,
    align: 'left',
    // format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'transactionType',
    label: 'Transaction Type',
    // minWidth: 170,
    align: 'left',
    // format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'payment',
    label: 'Payment',
    // minWidth: 170,
    align: 'left',
    // format: (value: number) => value.toFixed(2),
  },
  {
    id: 'receipt',
    label: 'Receipt',
    // minWidth: 170,
    align: 'left',
    // format: (value: number) => value.toFixed(2),
  },
]

export function useForceUpdate() {
  const [, setIt] = useState(false)
  return () => setIt((it) => !it)
}

export default function TransactionHistory() {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const userInfo = useSelector((state: RootState) => state.userReducer.userInfo)

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, 'month')
  )
  const forceUpdate = useForceUpdate()
  const [endDate, setEndDate] = useState(moment(new Date()))
  const [rows, setRows] = useState([] as any)
  const [transActions, setTransActions] = useState([])
  const [minENdDate, setMinEndDate] = useState(startDate)

  const handleEndDateChanged = (date: Date | null) => {
    setEndDate(moment(date))
  }

  const handleStartDateChanged = (date: any | null) => {
    setStartDate(date)
    setMinEndDate(date)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getTransactions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (transActions.length > 0) {
      handleData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transActions])

  async function getTransactions() {
    setTransActions([])
    setRows([])
    setIsLoading(true)
    try {
      let data = {
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await transactionsRequest(data)

      if (response.data.status === 'success') {
        setIsLoading(false)
        sortByDate(response.data.data.reverse())
      } else {
        setIsLoading(false)
        alert(response.data.message)
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data)
      }
      setIsLoading(false)
    }
  }

  function sortByDate(data: any) {
    let newData: any = []
    Object.entries(data).map(([key, value]) => {
      if (
        // @ts-ignore
        moment.unix(value.date).isAfter(moment(startDate)) &&
        // @ts-ignore
        moment.unix(value.date).isBefore(moment(endDate))
      ) {
        newData.push(value)
      }
      return newData
    })

    let sortedDates = newData
    sortedDates.sort((a: any, b: any) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    })

    setTransActions(sortedDates)
    setIsLoading(false)
  }

  function handleData() {
    // eslint-disable-next-line array-callback-return
    transActions.map((item: any) => {
      rows.push(
        createData(
          item.reg,
          item.location_name,
          moment.unix(item.date).format('DD MMM, YYYY'),
          item.transaction_type,
          `£${Math.abs(item.payment_amount / 100).toFixed(2)}`,
          '',
          item.activity_id
        )
      )
    })
    forceUpdate()
  }

  interface Data {
    vehicleReg: string
    terminal: string
    paymentDate: string
    transactionType: string
    payment: string
    receipt: string
    activityId: number
  }

  function createData(
    vehicleReg: string,
    terminal: string,
    paymentDate: string,
    transactionType: string,
    payment: string,
    receipt: string,
    activityId: number
  ): Data {
    return {
      vehicleReg,
      terminal,
      paymentDate,
      transactionType,
      payment,
      receipt,
      activityId,
    }
  }

  function onSearchClicked() {
    getTransactions()
  }

  async function sendReceiptClicked(receipt: any) {
    try {
      let data = {
        activity_id: receipt.activityId,
        receiver_email: userInfo.email,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await resendReceipt(data)

      if (response.data.status === 'success') {
        alert(`A new receipt have now been sent to ${userInfo.email}`)
      }
    } catch (error) {
      console.log('error', error)
      if (error.response) {
        alert(error.response.data.message)
      }
    }
  }

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Transaction history" titleStep="" />

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper style={styles.paper}>
            <div>
              <p
                style={{
                  color: 'black',
                  fontSize: 14,
                  textAlign: 'left',
                }}
              >
                Below is your transaction history for the last 31 days. You can
                search your Drop Off history up to 12 months back from today.
                Simply enter chosen start and end date range and press Search.
              </p>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              width: '100%',
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <label
                    style={{
                      color: 'black',
                      display: 'grid',
                      fontWeight: 'bold',
                      fontSize: 12,
                    }}
                  >
                    Drop Off start date
                  </label>
                  <KeyboardDatePicker
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline,
                      },
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabelRoot,
                      },
                    }}
                    style={{
                      border: 'solid',
                      borderWidth: '1px',
                      borderColor: 'black',
                      borderRadius: 5,
                      marginRight: 5,
                      height: '25px',
                      padding: 5,
                      justifyContent: 'center',
                    }}
                    disableToolbar
                    variant="inline"
                    format="dd MMM, yyyy"
                    margin="normal"
                    inputVariant="standard"
                    id="date-picker-inline"
                    value={startDate}
                    onChange={handleStartDateChanged}
                    autoOk={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    maxDate={new Date()}
                    minDate={moment().subtract(1, 'year')}
                  />
                </div>
                <div>
                  <label
                    style={{
                      color: 'black',
                      display: 'grid',
                      fontWeight: 'bold',
                      fontSize: 12,
                      paddingLeft: 5,
                    }}
                  >
                    Drop Off end date
                  </label>
                  <KeyboardDatePicker
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.inputLabelRoot,
                      },
                    }}
                    style={{
                      border: 'solid',
                      borderWidth: '1px',
                      borderColor: 'black',
                      borderRadius: 5,
                      marginLeft: 5,
                      height: '25px',
                      padding: 5,
                      justifyContent: 'center',
                    }}
                    disableToolbar
                    margin="normal"
                    id="date-picker-dialog"
                    autoOk={true}
                    variant="inline"
                    format="dd MMM, yyyy"
                    inputVariant="standard"
                    className={classes.datePickerInput}
                    value={endDate}
                    onChange={handleEndDateChanged}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDate={minENdDate}
                    maxDate={new Date()}
                    minDateMessage={''}
                  />
                </div>
              </MuiPickersUtilsProvider>
              <Button
                className={classes.buttonSave}
                onClick={() => onSearchClicked()}
              >
                SEARCH
              </Button>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={false} className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <TableContainer
              style={{
                maxHeight: 440,
                border: 'solid',
                borderWidth: '1px',
                borderColor: '#212529',
                borderBottomWidth: '0',
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: '#004489',
                          borderRight: 'solid',
                          borderRightWidth: '1px',
                          fontFamily: 'conduit',
                          height: '35px',
                          padding: '10px',
                          borderRightColor: '#212529',
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length > 0
                    ? rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any, index: any) => {
                          return (
                            <TableRow
                              tabIndex={-1}
                              style={{
                                background: index % 2 ? '#f1f1f1' : 'white',
                              }}
                              key={row.payment}
                            >
                              {columns.map((column) => {
                                const value = row[column.id]
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      color: 'black',
                                      height: '10px',
                                      padding: '10px',
                                      fontFamily: 'conduit',
                                    }}
                                  >
                                    {value instanceof Date
                                      ? moment(value).format('DD-MMM, YYYY')
                                      : value}

                                    {column.id === 'receipt' && (
                                      <Button
                                        onClick={() => sendReceiptClicked(row)}
                                        style={{
                                          color: 'black',
                                          textTransform: 'none',
                                          textDecoration: 'underline',
                                          fontFamily: 'conduit',
                                        }}
                                      >
                                        Resend
                                      </Button>
                                    )}
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })
                    : !isLoading && ''}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              style={{
                color: 'black',
                border: 'solid',
                borderWidth: '1px',
                borderColor: '#212529',
                borderTopWidth: '0px',
                width: '100%',
              }}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <BottomButtons showSaveBtn={false} onBackClicked="/main" />
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      display: 'flex',
      justifyContent: 'center',
      width: '70%',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      width: '100%',
    },
    datePickerInput: {
      color: 'black',
    },
    inputRoot: {
      color: 'black',
      '& .MuiIconButton-root': {
        color: 'black',
      },
      fontFamily: 'conduit',
      '& .MuiInputBase-input ': {
        color: 'black',
      },
      '& $focused$notchedOutline': {
        borderColor: 'orange',
      },
    },
    inputLabelRoot: {
      color: 'black',
    },
    focused: {},
    notchedOutline: {},
    buttonSave: {
      color: 'white',
      backgroundColor: '#004489',
      width: 125,
      fontFamily: 'conduit',
      marginLeft: '20px',
      marginTop: '25px',
      fontSize: 14,
      height: '40px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#004489',
        color: '#FFF',
      },
    },
  })
)
