import Iframe from 'react-iframe'

export default function PostPay() {
  return (
    <div style={{ marginTop: 100 }}>
      <Iframe
        url="https://paystaging.simplyture.com/?customKey=forwardtest"
        width="100%"
        height="130%"
        frameBorder={0}
        id="myId"
        target="_parent"
        className="myClassname"
        position="absolute"
      />
    </div>
  )
}
