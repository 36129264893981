import request from './'

export function createAccountRequet(data = {}) {
  return request.post('/ignition/account/createaccountwithvehicle', data)
}

export function getUserAccountRequest(data = {}) {
  return request.post('/ignition/account/account', data)
}

export function loginRequest(data = {}) {
  return request.post('/ignition/login', data)
}

export function forgottenPassRequest(data = {}) {
  return request.post('/ignition/login/forgottenpassword', data)
}

export function editAcccoutRequest(data = {}) {
  return request.post('/ignition/account/editaccount', data)
}

export function getVehiclesRuquest(data = {}) {
  return request.post('/ignition/vehicle/get', data)
}

export function addVehicleRequest(data = {}) {
  return request.post('/ignition/vehicle/create', data)
}

export function deleteVehicleRequest(data = {}) {
  return request.post('/ignition/vehicle/delete', data)
}

export function changePasswordRequest(data = {}) {
  return request.post('/ignition/account/changepassword', data)
}

export function transactionsRequest(data = {}) {
  return request.post('/ignition/usage/gatwick/usage', data)
}

export function getPaymentDetails(data = {}) {
  return request.post('/ignition/login/reauth', data)
}

export function applePaySessionURL(data = {}) {
  return request.post('/ignition/payment/apw/merchantvalidation', data)
}

export function performTransactionRequest(data = {}) {
  return request.post('/payment/applepay/pay', data)
}

export function registerGooglePaymentToken(data = {}) {
  return request.post(
    '/ignition/ajax/paymentmethod/registergooglegatewaypaymenttoken',
    data
  )
}

export function ekashuFormRequest(data = {}) {
  return request.post('/ignition/payment/getekashuform', data)
}

export function requestPaymentMethod(data = {}) {
  return request.post('/ignition/payment/paymentmethods', data)
}

export function removePaymentMethod(data = {}) {
  return request.post('/ignition/payment/removepaymentmethod', data)
}

export function logoutRequest(data = {}) {
  return request.post('/ignition/logout', data)
}

export function resendReceipt(data = {}) {
  return request.post('/ignition/usage/receipt', data)
}
