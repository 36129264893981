import { IS_USER_LOGGED_IN, REMEMBER_ME } from '../actions/types'

const initialState = {
  isLoggedIn: false,
  rememberMe: false,
}

function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case IS_USER_LOGGED_IN:
      return { ...state, isLoggedIn: action.loggedin }
    case REMEMBER_ME:
      return { ...state, rememberMe: action.remember }
    default:
      return state
  }
}

export default authReducer
