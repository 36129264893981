import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { styles } from '../styles'
import { BodyHeader, CustomTextField, BottomButtons } from '../../components'
import configData from '../config/index.json'
import { changePasswordRequest } from '../../services/api'
import { useHistory } from 'react-router-dom'

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] =
    useState('') /* this is set everytime j8b83Ttg52T5LHS */
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  function handleCurrentPassword(pass: any | null) {
    setCurrentPassword(pass.target.value)
  }

  function handleNewPassword(pass: any | null) {
    setNewPassword(pass.target.value)
  }

  function handleRepeatPassword(pass: any | null) {
    setRepeatPassword(pass.target.value)
  }

  async function handleChangePasswordClicked() {
    if (currentPassword === '' || newPassword === '' || repeatPassword === '') {
      alert('One or more fields are empty')
      return
    }

    if (newPassword !== repeatPassword) {
      alert('New and repeat password mismatch')
      return
    }

    setIsLoading(true)

    try {
      let data = {
        password: currentPassword,
        newpassword: newPassword,
        newpassword_confirmation: repeatPassword,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await changePasswordRequest(data)

      if (response.data.status === 'success') {
        setIsLoading(false)
        setCurrentPassword('')
        setRepeatPassword('')
        setNewPassword('')
        history.replace('/main')
        alert('Password has changed succesfully')
      } else {
        if (response.data.newpassword) {
          alert(response.data.newpassword)
        } else {
          alert(response.data.message)
        }
        setIsLoading(false)
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data)
      }
      setIsLoading(false)
    }
  }

  function keyPressed(e: any) {
    if (e.key === 'Enter') {
      handleChangePasswordClicked()
    }
  }

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Change Password" titleStep="" />

        {/** Current Password Field */}
        <CustomTextField
          fieldTitle="Current Password"
          justifyContent="flex-start"
          helperText=" "
          style={{ height: 40 }}
          type="password"
          autocomplete
          txtValue={currentPassword}
          onChange={handleCurrentPassword}
        />

        {/** New Password Field */}
        <CustomTextField
          fieldTitle="New Password"
          justifyContent="flex-start"
          helperText=" "
          style={{ height: 40 }}
          type="password"
          txtValue={newPassword}
          onChange={handleNewPassword}
        />

        {/** Repeat Password Field */}
        <CustomTextField
          fieldTitle="Repeat Password"
          justifyContent="flex-start"
          helperText=" "
          style={{ height: 40 }}
          type="password"
          onKeyDown={keyPressed}
          isPasswordField={true}
          txtValue={repeatPassword}
          onChange={handleRepeatPassword}
        />

        <BottomButtons
          editBtnTxt={'Change'}
          loading={isLoading}
          onEditClicked={() => handleChangePasswordClicked()}
          onBackClicked="/main"
        />
      </Grid>
    </div>
  )
}
