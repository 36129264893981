import GooglePayButton from "@google-pay/button-react";
import configData from "../../screen/config/index.json";
import {

  registerGooglePaymentToken,
} from "../../services/api";
const purchase_amount = "0";
const GOOGLE_PAY_MERCHANT_NAME = "Gatwick Airport";

const config = {
  payments: {
    acceptedCardSchemes: ["amex", "masterCard", "maestro", "visa", "mada"],
  },
  shop: {
    product_price: purchase_amount,
    shop_name: GOOGLE_PAY_MERCHANT_NAME,
    shop_localisation: {
      currencyCode: "GBP",
      countryCode: "GB",
    },
  },
  shipping: {
    GB_region: [
      {
        label: "Free Shipping",
        amount: "0.00",
        detail: "Arrives in 3-5 days",
        identifier: "freeShipping",
      },
      {
        label: "Express Shipping",
        amount: "5.00",
        detail: "Arrives in 1-2 days",
        identifier: "expressShipping",
      },
    ],
    WORLDWIDE_region: [
      {
        label: "Worldwide Standard Shipping",
        amount: "10.00",
        detail: "Arrives in 5-8 days",
        identifier: "worldwideShipping",
      },
    ],
  },
};

export default function GooglePay() {

  async function registerGoogleGatewayPaymentToken(payment_token: any) {
    try {
      let data = {
        payment_token: payment_token,
        amount: purchase_amount,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      };
      const response = await registerGooglePaymentToken(data);

      if (response.data.status === "success") {
      }

      console.log("response", response.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <GooglePayButton
      environment="TEST"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: [ 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: [
                "AMEX",
                "DISCOVER",
                "INTERAC",
                "JCB",
                "MASTERCARD",
                "VISA",
              ],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "gatewayservices",
                gatewayMerchantId: "25057313",
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: "6954-5263-7142",
          merchantName: "Gatwick Airport",
        },
        transactionInfo: {
          totalPriceStatus: "FINAL",
          totalPriceLabel: "Total",
          totalPrice: purchase_amount,
          currencyCode: config.shop.shop_localisation.currencyCode,
          countryCode: config.shop.shop_localisation.countryCode,
        },
      }}
      onLoadPaymentData={(paymentRequest) =>
        registerGoogleGatewayPaymentToken(
          paymentRequest.paymentMethodData.tokenizationData.token
        )
      }
    />
  );
}

