import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ncpLogo from '../assets/images/ncp_logo.png'
export default function Footer() {
  const classes = useStyles()
  return (
    <div className={classes.footer}>
      <div className={classes.footerWrapper}>
        <Button
          className={classes.button}
          onClick={() => {
            window.open(
              'https://www.ncp.co.uk/help-centre/contact-us/',
              '_blank'
            )
          }}
        >
          Contact us
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            window.open(
              'https://www.ncp.co.uk/help-centre/website-terms-and-conditions/privacy-and-cookie-policy/',
              '_blank'
            )
          }}
        >
          Privacy Policy
        </Button>
        <Button
          className={classes.button}
          onClick={() => {
            window.open(
              'https://www.gatwickairport.com/to-and-from/picking-up-dropping-off/',
              '_blank'
            )
          }}
        >
          Gatwick Airport
        </Button>

        <img src={ncpLogo} alt="ncp" style={{ height: 30, width: 90 }} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#F3F3F3',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  footerWrapper: {
    width: '60%',
    display: 'flex',
    height: 70,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  button: {
    color: 'black',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'conduit',
    fontSize: 14,
  },
}))
