import React, { FC, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import PaymentOptions from './PaymentOptions';
import { Link } from 'react-router-dom';

interface FieldsInterface {
	children: React.ReactNode;
	title?: string;
}

const Fields: FC<FieldsInterface> = ({ children, title }) => (
	<div
		style={{
			flex: 1,
			height: 30,
			marginTop: 30,
			display: 'flex',
			alignItems: 'center'
		}}
	>
		<label
			style={{
				fontSize: 16,
				flex: 0.3,

				color: '#004489'
			}}
		>
			{title}
		</label>
		{children}
	</div>
);

interface ValueInterface {
	children?: React.ReactNode;
	title?: string;
}

const Value: FC<ValueInterface> = ({ children, title }) => (
	<div
		style={{
			flex: 1,
			height: 20,
			marginTop: 20,
			display: 'flex',
			alignItems: 'center'
		}}
	>
		<label
			style={{
				fontSize: 16,
				fontWeight: 'normal',
				color: '#004489',
				paddingRight: '10px'
			}}
		>
			{title}
		</label>
		<p
			style={{
				fontWeight: 'lighter',
				color: '#717980'
			}}
		>
			{children}
		</p>
	</div>
);

interface ContainerInterface {
	children: React.ReactNode;
}

const Container: FC<ContainerInterface> = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={0} justify={'center'} alignItems="center" direction="row">
				<Grid item xs={false} className={classes.gridContainer}>
					<h4 style={{ fontSize: 16, fontWeight: 'bolder', color: '#004489' }}>
						<Link style={{ textDecoration: 'none', color: '#004489' }} to="/">
							Home
						</Link>{' '}
						&gt; Pre-Pay
					</h4>
					<Typography variant="h6" className={classes.containerTitle}>
						Pre-Pay
					</Typography>
				</Grid>
				<Grid item xs={false} className={classes.gridContainer}>
					{children}
				</Grid>
			</Grid>
		</div>
	);
};

export const emailValidator = (email: string) => {
	const re = /\S+@\S+\.\S+/;

	if (!email || email.length <= 0) return 'Email cannot be empty.';
	if (!re.test(email)) return 'Ooops! We need a valid email address.';

	return '';
};

export default function PrePay() {
	const [ total, setTotalt ] = React.useState<number>(5);
	const [ vehicle, setVehicle ] = React.useState<string>('');
	const [ vehicleEmpty, setVehicleEmpty ] = React.useState<boolean>(false);
	const [ selectedDate, handleDateChange ] = React.useState<Date | null>(new Date());
	const [ email, setEmail ] = React.useState<string>('');
	const [ emailError, setEmailError ] = React.useState<string>('hej');
	const [ emailEmpty, setEmailEmpty ] = React.useState<boolean>(false);

	const [ paymentComplition, setPaymentComplition ] = React.useState<boolean>(false);

	const [ checked, setChecked ] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const [ DataInput, setDataInput ] = useState(false);

	const classes = useStyles();
	const onSubmit = () => {
		const emailError = emailValidator(email);
		if (vehicle === '') {
			setVehicleEmpty(true);
			return;
		}
		setVehicleEmpty(false);
		if (emailError) {
			setEmailEmpty(true);
			setEmailError('Please enter a valid email address');
			return;
		}

		setDataInput(true);
	};

	const setVehicleChanges = (value: string) => {
		setVehicleEmpty(false);
		setVehicle(value);
	};
	const setEmailChanges = (value: string) => {
		setEmailEmpty(false);
		setEmail(value);
	};

	const paymentComplite = () => {
		setPaymentComplition(true);
		setChecked(false);
	};

	if (!DataInput) {
		return (
			<Container>
				<Fields title="Vehicle Registration*">
					<CssTextField
						error={vehicleEmpty}
						id="outlined-basic"
						label=""
						helperText="Please enter Vehicle Registration."
						placeholder="Enter registration"
						variant="outlined"
						required
						onChange={(data) => setVehicleChanges(data.target.value)}
						style={{ width: '50px' }}
						className={classes.txtStyle}
					/>
				</Fields>
				<Fields title="Entry Date*">
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<CssDatePicker
							autoOk
							variant="inline"
							className={classes.txtStyle}
							inputVariant="outlined"
							format="dd/MM/yyyy"
							value={selectedDate}
							onChange={(date) => handleDateChange(date)}
						/>
					</MuiPickersUtilsProvider>
				</Fields>
				<Fields title="Total">
					<CssTextFieldPrice
						id="outlined-basic"
						label=""
						placeholder={`£${total}`}
						variant="outlined"
						disabled
						className={classes.txtStyle}
					/>
				</Fields>
				<Fields title="Email Address*">
					<CssTextField
						error={emailEmpty}
						id="outlined-basic"
						label=""
						helperText={emailError}
						placeholder="Enter email address"
						variant="outlined"
						onChange={(data) => setEmailChanges(data.target.value)}
						required
						style={{ width: '50px' }}
						className={classes.txtStyle}
					/>
				</Fields>

				<div style={{ marginTop: 30 }}>
					<label style={{ color: 'gray', fontSize: 12 }}>
						Your email address will be used for receipt purposes only.
					</label>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<Button
						onClick={() => onSubmit()}
						variant="contained"
						color="primary"
						style={{ textTransform: 'none' }}
						disableElevation
						size="large"
					>
						Continue
					</Button>
				</div>
			</Container>
		);
	}

	return (
		<Container>
			<Grid container spacing={0}>
				<Grid item xs={12} sm={6} className={classes.gridContainer}>
					<Typography variant="h6">
						{paymentComplition ? (
							'Thank you. Your payment was successful. Please check your email for your receipt.'
						) : (
							'Drop Off Details'
						)}
					</Typography>
					<Value title="This drop off period is valid for one single visit." />
					<Value title="Vehicle Registration:"> {vehicle}</Value>
					{selectedDate ? (
						<Value title="Entry Date:">
							{' '}
							{`${selectedDate.getDate()} ${selectedDate.getMonth()} ${selectedDate.getFullYear()}`}
						</Value>
					) : null}
					<Value title="Total:"> {`£${total}`}</Value>
					<Value title="Email Address:"> {email}</Value>
					{!paymentComplition ? (
						<FormControlLabel
							control={
								<BlueCheckbox
									checked={checked}
									onChange={handleChange}
									color="default"
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
							}
							label="I've read and agree the Terms & Conditions"
						/>
					) : null}
				</Grid>

				<Grid item xs={12} sm={6}>
					{checked ? <PaymentOptions paymentComplite={paymentComplite} /> : null}
				</Grid>
				{paymentComplition ? (
					<Grid container direction="row" justify="flex-end" alignItems="center" xs={12}>
						<Link to="/">
            <Button
							style={{ float: 'right' }}
							onClick={() => paymentComplite()}
							variant="contained"
							color="primary"
							disableElevation
						>
							Return Home
						</Button>
            </Link>
					</Grid>
				) : null}
			</Grid>
		</Container>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			'@media (max-width: 675px)': {
				marginTop: 120
			},
			marginTop: 130
		},
		gridContainer: {
			'@media (max-width: 675px)': {
				width: '100%'
			},
			width: '70%'
		},
		paper: {
			backgroundColor: 'transparent',
			boxShadow: 'none'
		},
		txtStyle: {
			'@media (max-width: 675px)': {
				width: 200
			},
			flex: 1,
			width: 300,
			height: 30,
			justifyContent: 'center',
			display: 'flex'
		},
		containerTitle: {
			fontWeight: 'bolder',
			'@media (max-width: 675px)': {
				fontSize: 20
			},
			fontSize: 30,
			color: '#004489'
		},
		btnContinue: {
			color: 'white',
			backgroundColor: '#004489'
		}
	})
);

const BlueCheckbox = withStyles({
	root: {
		color: '#004489',
		'&$checked': {
			color: '#004489'
		}
	},
	checked: {}
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#F3F3F3',
			borderWidth: 2,
			borderRadius: 2,
			color: 'black',
			height: 30,
			width: '300px'
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#004489'
		},
		'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
			borderColor: '#f44336'
		},
		'& .MuiOutlinedInput-input': {
			color: 'black',
			width: '300px',
			padding: 5
		},
		'&:hover .MuiOutlinedInput-input': {
			color: 'black'
		}
	}
})(TextField);
const CssDatePicker = withStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#F3F3F3',
			borderWidth: 2,
			borderRadius: 2,
			color: 'black',
			height: 30,
			width: '300px'
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#004489'
		},
		'& .MuiOutlinedInput-input': {
			color: 'black',
			width: '300px',
			padding: 5
		},
		'&:hover .MuiOutlinedInput-input': {
			color: 'black'
		}
	}
})(DatePicker);

const CssTextFieldPrice = withStyles({
	root: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: '#F3F3F3',
			borderWidth: 2,
			borderRadius: 2,
			backgroundColor: '#d5d7d8',
			color: 'black',
			height: 30,
			zIndex: -1,
			width: '300px'
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: '#004489'
		},
		'& .MuiOutlinedInput-input': {
			color: 'black',
			padding: 5
		},
		'&:hover .MuiOutlinedInput-input': {
			color: 'black'
		}
	}
})(TextField);
