import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ImageMore from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import configData from '../screen/config/index.json'
import { deleteVehicleRequest } from '../services/api'

export default function VehicleComponent({
  vehcileTitle = '',
  // @ts-ignore
  item,
  // @ts-ignore
  getVehicles,
}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  async function handleDeleteVehicle(item: any | null) {
    setIsDeleteLoading(true)

    try {
      let data = {
        entity_id: item.id,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await deleteVehicleRequest(data)

      if (response.data.status === 'success') {
        setIsDeleteLoading(false)
        getVehicles()
      } else {
        alert(response.data.message)
        setIsDeleteLoading(false)
      }
    } catch (error) {
      setIsDeleteLoading(false)
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
    }
  }

  function handleShowDialog() {
    setShowDeleteDialog(true)
  }

  return (
    <Grid item xs={false} className={classes.gridContainer}>
      <Paper className={classes.paper}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className={classes.lblVehicleContainer}>
            {/** Label style enter vehicle */}
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <label className={classes.lblEnterVehicle}>{vehcileTitle}</label>
            </div>

            {/** Label style enter vehicle */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <div className={classes.inputVehicleContainer}>
                <div
                  style={{
                    backgroundColor: 'black',
                    borderRadius: 99,
                    width: 10,
                    height: 10,
                  }}
                />

                <CssTextField
                  id="outlined-basic"
                  label=""
                  placeholder="e.g ABC 123456"
                  variant="outlined"
                  required
                  disabled
                  value={item && item.reg !== null ? item.reg : ''}
                  style={{
                    width: 200,
                    height: 30,
                    justifyContent: 'center',

                    display: 'flex',
                  }}
                />

                <div
                  style={{
                    backgroundColor: 'black',
                    borderRadius: 99,
                    width: 10,
                    height: 10,
                  }}
                />
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  style={{
                    justifyContent: 'flex-end',
                  }}
                  onClick={handleClick}
                >
                  <ImageMore color="primary" />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  onClick={handleClose}
                >
                  <MenuItem onClick={() => handleShowDialog()}>
                    <label style={{ fontFamily: 'conduit' }}>Delete</label>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {showDeleteDialog && (
        <Dialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{
              backgroundColor: '#004489',
            }}
          >
            <label style={{ fontFamily: 'conduit', fontSize: 20 }}>
              Delete vehicle
            </label>
          </DialogTitle>
          <DialogContent style={{ backgroundColor: '#004489' }}>
            <DialogContentText>
              <label style={{ fontFamily: 'conduit', fontSize: 16 }}>
                Do you want to delete vehicle {item.reg}
              </label>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#004489' }}>
            <Button
              onClick={() => setShowDeleteDialog(false)}
              color="primary"
              style={{ color: 'red', fontFamily: 'conduit' }}
            >
              Cancel
            </Button>
            {isDeleteLoading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              <Button
                onClick={() => handleDeleteVehicle(item)}
                style={{ color: 'white', fontFamily: 'conduit' }}
              >
                Delete
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      '@media (max-width: 675px)': {
        width: '100%',
        padding: 5,
      },
      width: '70%',
    },
    paper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    lblVehicleContainer: {
      flex: 1,
      '@media (max-width: 675px)': {
        display: 'block',
      },
      display: 'flex',
      // justifyContent: 'center',
      paddingLeft: 10,
    },
    lblEnterVehicle: {
      flex: 1,
      color: 'black',
      '@media (max-width: 675px)': {
        textAlign: 'center',
        marginBottom: 10,
      },
      textAlign: 'left',
    },
    lblDefault: {
      flex: 1,
      color: 'black',
      '@media (max-width: 675px)': {
        textAlign: 'center',
      },
      fontSize: '0.8em',
      textAlign: 'left',
      paddingLeft: 10,
    },
    inputVehicleContainer: {
      backgroundColor: '#fbda33',
      '@media (max-width: 675px)': {
        width: '80%',
      },
      width: 275,
      height: 35,
      marginLeft: 10,
      borderRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  })
)

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderWidth: 2,
      borderRadius: 2,
      color: 'black',
      height: 30,
    },

    // '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    //   borderColor: 'red',
    // },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      padding: 5,
      textAlign: 'center',
    },
    '&:hover .MuiOutlinedInput-input': {
      color: 'black',
      textAlign: 'center',
    },
    // '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    //   color: 'purple',
    // },
    // '& .MuiInputLabel-outlined': {
    //   color: 'green',
    // },
    // '&:hover .MuiInputLabel-outlined': {
    //   color: 'red',
    // },
    // '& .MuiInputLabel-outlined.Mui-focused': {
    //   color: 'purple',
    // },
  },
})(TextField)
