import { USER_INFO } from '../actions/types'

const initialState = {
  userInfo: {},
}

function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case USER_INFO:
      return { ...state, userInfo: action.userinfo }
    default:
      return state
  }
}

export default userReducer
