function getDomain() {
  // return 'https://test.gatwick.ncp.spark.parkpark.dk'
  return 'https://gatwick.ncp.spark.parkpark.dk'
}

function getStagingDomain() {
  return 'https://test.gatwick.ncp.spark.parkpark.dk'
}

function webviewURL() {
  return getDomain()
}

const domain = getDomain()
const webURL = webviewURL()
const apiStagingDomain = getStagingDomain() + '/api'
const apiDomain = getDomain() + '/api'

export { domain, apiDomain, webURL, apiStagingDomain }
