import { styles } from '../styles'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { BodyHeader, CustomTextField, BottomButtons } from '../../components'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isEmail from 'validator/lib/isEmail'
import configData from '../config/index.json'
import { editAcccoutRequest } from '../../services/api'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { setUserInfo } from '../../redux/actions/user'

export default function PersonalDetails() {
  const dispatch = useDispatch()
  const userinfo: any = useSelector(
    (state: RootState) => state.userReducer.userInfo
  )

  const [showLabel, setShowLabel] = useState(true)
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [emailAdd, setEmailAdd] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [postCode, setPostCode] = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [changesHaveBeenMade, setChangesHaveBeenMade] = useState(false)

  const [showEmailHelperText, setShowEmailHelperText] = useState(false)

  useEffect(() => {
    setName(userinfo.name)
    setLastname(userinfo.lastname)
    setPhoneNo(userinfo.phone)
    setEmailAdd(userinfo.email)
    setCity(userinfo.city)
    setAddressLine1(userinfo.address)
    setAddressLine2(userinfo.address2)
    setPostCode(userinfo.zip)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleFirstNameChanged(name: any | null) {
    setChangesHaveBeenMade(true)
    setName(name.target.value)
  }

  function handleLastNameChanged(name: any | null) {
    setChangesHaveBeenMade(true)
    setLastname(name.target.value)
  }

  function handlePhoneNoChanged(phone: any | null) {
    setChangesHaveBeenMade(true)
    if (phone.target.value === '') {
      setPhoneNo(phone.target.value)
      setIsPhoneValid(false)
      return
    }

    if (!isMobilePhone(phone.target.value)) {
      setIsPhoneValid(true)
      setPhoneNo(phone.target.value)
      return
    }

    setIsPhoneValid(false)
    setPhoneNo(phone.target.value)
  }

  function handleEmaiLChanged(email: any | null) {
    setChangesHaveBeenMade(true)
    if (email.target.value === '') {
      setShowEmailHelperText(false)
      setEmailAdd(email.target.value)
      setInvalidEmail(false)
      return
    }
    if (!isEmail(email.target.value)) {
      setShowEmailHelperText(true)
      setInvalidEmail(true)
      setEmailAdd(email.target.value)
      return
    }

    setShowEmailHelperText(false)
    setInvalidEmail(false)
    setEmailAdd(email.target.value)
  }

  function handleAddressLine1Changed(address: any | null) {
    setChangesHaveBeenMade(true)
    setAddressLine1(address.target.value)
  }

  function handleAddressLine2Changed(address: any | null) {
    setChangesHaveBeenMade(true)
    setAddressLine2(address.target.value)
  }

  function handleCityChanged(city: any | null) {
    setChangesHaveBeenMade(true)
    setCity(city.target.value)
  }

  function handlePostCodeChanged(postcode: any | null) {
    setChangesHaveBeenMade(true)
    setPostCode(postcode.target.value)
  }

  async function onSaveClicked() {
    setIsLoading(true)

    try {
      let data = {
        name: name,
        lastname: lastname,
        phone: phoneNo,
        email: emailAdd,
        address: addressLine1,
        address2: addressLine2,
        city: city,
        zip: postCode,
        apikey: configData.APIKEY,
        userlocale: configData.USERLOCALE,
      }

      const response = await editAcccoutRequest(data)

      if (response.data.status === 'success') {
        dispatch(setUserInfo(response.data.data))
        alert('Personal Details edit successfully')
        setIsLoading(false)
        setShowLabel(true)
      } else {
        alert(response.data.message)
        setIsLoading(false)
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data)
      }
      console.log('error', error.response)
      setIsLoading(false)
    }
  }

  return (
    <div style={styles.root}>
      <Grid
        container
        spacing={0}
        justify={'center'}
        alignItems="center"
        direction="row"
      >
        <BodyHeader title="Personal Details" titleStep="" />

        {/** Name Field */}
        <CustomTextField
          fieldTitle="First name"
          justifyContent="flex-start"
          showLabel={showLabel}
          style={{ height: 40 }}
          txtValue={name}
          inputValue={name}
          onChange={handleFirstNameChanged}
        />
        <CustomTextField
          fieldTitle="Last name"
          justifyContent="flex-start"
          showLabel={showLabel}
          txtValue={lastname}
          style={{ height: 40 }}
          inputValue={lastname}
          onChange={handleLastNameChanged}
        />

        {/** Phone number Field */}
        <CustomTextField
          fieldTitle="Phone number"
          justifyContent="flex-start"
          error={isPhoneValid ? true : false}
          showLabel={showLabel}
          type="text"
          style={{ height: 40 }}
          txtValue={phoneNo}
          inputValue={phoneNo}
          onChange={handlePhoneNoChanged}
        />
        {/** Email Field */}
        <CustomTextField
          fieldTitle="Email"
          justifyContent="flex-start"
          showLabel={showLabel}
          error={invalidEmail ? true : false}
          helperText={showEmailHelperText ? 'Invalid Email' : ''}
          style={{ height: 40 }}
          txtValue={emailAdd}
          inputValue={emailAdd}
          onChange={handleEmaiLChanged}
        />
        {/** Address Line 1 field*/}
        <CustomTextField
          fieldTitle="Address line 1"
          justifyContent="flex-start"
          showLabel={showLabel}
          style={{ height: 40 }}
          txtValue={addressLine1 === '0' ? '' : addressLine1}
          inputValue={addressLine1 === '0' ? 'Unknown' : addressLine1}
          onChange={handleAddressLine1Changed}
        />
        {/** Address Line 2 field*/}
        <CustomTextField
          fieldTitle="Address line 2"
          justifyContent="flex-start"
          style={{ height: 40 }}
          showLabel={showLabel}
          onChange={handleAddressLine2Changed}
          txtValue={
            addressLine2 === '0' || addressLine2 === '' ? '' : addressLine2
          }
          inputValue={
            addressLine2 === '0' || addressLine2 === ''
              ? 'Unknown'
              : addressLine2
          }
        />
        {/** Town/City field*/}
        <CustomTextField
          fieldTitle="Town/City"
          justifyContent="flex-start"
          showLabel={showLabel}
          style={{ height: 40 }}
          txtValue={city === '0' ? '' : city}
          onChange={handleCityChanged}
          inputValue={city === '0' || city === '' ? 'Unknown' : city}
        />
        {/** Postcode field*/}
        <CustomTextField
          fieldTitle="Postcode"
          justifyContent="flex-start"
          showLabel={showLabel}
          style={{ height: 40 }}
          onChange={handlePostCodeChanged}
          txtValue={postCode === '0' ? '' : postCode}
          inputValue={
            postCode === '0' || postCode === '' ? 'Unknown' : postCode
          }
        />
        <BottomButtons
          editBtnTxt={
            showLabel ? 'Edit' : changesHaveBeenMade ? 'Save' : 'Cancel'
          }
          loading={isLoading}
          onEditClicked={() =>
            showLabel
              ? setShowLabel(false)
              : changesHaveBeenMade
              ? onSaveClicked()
              : setShowLabel(true)
          }
          onBackClicked="/main"
        />
      </Grid>
    </div>
  )
}
